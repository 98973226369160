import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-8 ant-col-xl-8" }
const _hoisted_4 = { class: "ant-col ant-col-xs-14 ant-col-xl-14" }
const _hoisted_5 = { class: "ant-col ant-col-xs-2 ant-col-xl-2" }
const _hoisted_6 = { class: "ant-row" }
const _hoisted_7 = { class: "ant-col" }
const _hoisted_8 = { class: "ant-col" }
const _hoisted_9 = { class: "ant-col" }
const _hoisted_10 = { class: "ant-col" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_meio_pagamento = _resolveComponent("selecionar-meio-pagamento")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_card = _resolveComponent("card")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    keyboard: false,
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    width: 900,
    centered: "",
    "wrap-class-name": "ss-modal-cadastros"
  }, {
    title: _withCtx(() => [
      _createTextVNode(" Cadastro de Tipos de Documento "),
      _createVNode(_component_a_switch, {
        checked: _ctx.state.tipoDocumento.ativo,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.tipoDocumento.ativo) = $event)),
        style: {"float":"right"},
        "checked-children": "Ativa",
        "un-checked-children": "Inativa"
      }, null, 8, ["checked"])
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    footer: _withCtx(() => [
      ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir) || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("button", {
              type: "button",
              class: "ant-btn ant-btn-lg ant-btn-secondary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(true)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
            ]),
            _createElementVNode("button", {
              type: "button",
              class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvar(false)))
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_skeleton, {
        loading: _ctx.telaBase.carregando
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              _createVNode(_component_card, { titulo: "DADOS PRINCIPAIS" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_a_form_item, {
                        label: "Meio de Pagamento",
                        class: "ss-margin-campos",
                        rules: [{ required: true }]
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_meio_pagamento, {
                            valor: _ctx.state.tipoDocumento.meioPagamento,
                            "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.tipoDocumento.meioPagamento) = $event))
                          }, null, 8, ["valor"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_form_item, {
                        label: "Descrição",
                        class: "ss-margin-campos",
                        rules: [{ required: true }]
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            maxlength: "250",
                            class: "ant-input",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.tipoDocumento.descricao) = $event))
                          }, null, 512), [
                            [_vModelText, _ctx.state.tipoDocumento.descricao]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_form_item, {
                        label: "Sigla",
                        class: "ss-margin-campos",
                        rules: [{ required: true }]
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            maxlength: "4",
                            class: "ant-input",
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.tipoDocumento.sigla) = $event))
                          }, null, 512), [
                            [_vModelText, _ctx.state.tipoDocumento.sigla]
                          ])
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_card, { titulo: "DEFINIÇÕES" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_a_checkbox, {
                        class: "ss-margin-campos",
                        checked: _ctx.state.tipoDocumento.contasReceber,
                        "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.tipoDocumento.contasReceber) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Lançar Contas a Receber")
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_checkbox, {
                        class: "ss-margin-campos",
                        checked: _ctx.state.tipoDocumento.contasPagar,
                        "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.tipoDocumento.contasPagar) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Lançar Contas a Pagar")
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_a_checkbox, {
                        class: "ss-margin-campos",
                        checked: _ctx.state.tipoDocumento.movimentacaoEntrada,
                        "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.tipoDocumento.movimentacaoEntrada) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Utilizar em Entradas/Recebimentos")
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_a_checkbox, {
                        class: "ss-margin-campos",
                        checked: _ctx.state.tipoDocumento.movimentacaoSaida,
                        "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.tipoDocumento.movimentacaoSaida) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Utilizar em Saídas/Pagamentos")
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ])
                  ])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_11, [
                _withDirectives(_createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_a_form_item, {
                    label: "Empresas",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _ctx.telaBase.empresasSelecionadas,
                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event)),
                        mode: "multiple",
                        style: {"width":"100%"},
                        optionFilterProp: "label",
                        allowClear: true,
                        options: _ctx.telaBase.empresasDisponiveis
                      }, null, 8, ["value", "options"])
                    ]),
                    _: 1
                  })
                ], 512), [
                  [_vShow, _ctx.telaBase.empresasDisponiveis.length > 1]
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_requisicao_modal, {
        visivel: _ctx.modalBase.exibirRequisicaoModal,
        "onUpdate:visivel": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
        retornoRequisicao: _ctx.modalBase.retornoRequisicao,
        apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
        fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso
      }, null, 8, ["visivel", "retornoRequisicao", "apresentarBarraProgresso", "fraseBarraProgresso"])
    ]),
    _: 1
  }, 8, ["visible"]))
}